






































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import ShiftTypeEntryDialog from "@/components/master/shift/StaffShiftTypeEntry.vue";
import { Facility } from "@/components/master/office/common";
import {
  Office,
  OfficeHistory,
  DefaultOfficeHistorySameBuilding
} from "#/model/agreement";
import AppPlaces from "#/components/AppPlaces.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import SameBuildings from "@/components/master/office/SameBuildings.vue";
import TherapistVisitCount from "@/components/master/office/TherapistVisitCount.vue";

@Component({
  components: {
    ShiftTypeEntryDialog,
    AppPlaces,
    SameBuildings,
    TherapistVisitCount
  }
})
export default class OfficeEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Ref("form") public readonly form!: VForm;

  @Prop({ default: true }) showDatePicker!: boolean;
  //医療機関コードまたは事業所番号が未登録かどうか
  @Prop({ default: false }) isEnoughData!: boolean;
  //新規作成かどうか
  @Prop({ default: false }) isNewData!: boolean;
  //事業所情報
  @Prop() office!: OfficeHistory;

  @Prop({ default: () => [] }) facilities!: Facility[]; //同一建物情報

  @Prop({ default: () => [] }) therapistVisitCount!: Facility[]; //理学療法士超過減算

  @Prop({
    default: () => ({
      id: 0,
      kst_no: "",
      office_div: 0,
      office_name: ""
    })
  })
  cloneOffice!: Office;

  @Prop({ default: () => [] }) mainOffices!: Office[];

  @Prop({ default: false }) isReadOnly!: boolean;

  @Prop() isHonobono!: number;

  @Prop({ default: false }) is_history!: boolean; //履歴編集かどうか

  @Prop({ default: false }) is_init_data!: boolean; //初期データかどうか

  private isOpen = false; //ダイアログ

  /** する or しない */
  private doOrNot = [
    {
      text: "開催者のみが編集できる",
      value: 0
    },
    {
      text: "全ての職員が編集できる",
      value: 1
    }
  ];

  private openDialog() {
    this.isOpen = true;
  }

  private cancelDialog() {
    if (this.office.adl_setting_div == 1) {
      this.office.adl_setting_div = 0;
    } else {
      this.office.adl_setting_div = 1;
    }
    this.$nextTick(() => {
      this.isOpen = false;
    });
  }

  private saveDialog() {
    this.isOpen = false;
  }

  //サービス提供体制加算 項目
  private get CareServiceSupplyAddAfter() {
    const now = new Date();
    let yearmonth = Number(
      String(now.getFullYear()) + String(("00" + now.getMonth() + 1).slice(-2))
    );

    if (this.is_history && this.office.start_date) {
      yearmonth = this.getYearmonth(this.office.start_date);
    }

    if (yearmonth < 202104) {
      return this.masters.addition_or_not;
    }
    return this.masters.care_service_supply_add_after_r3;
  }

  //対象年月取得
  private getYearmonth(date: string): number {
    const [year, month] = date.split("-"); //開始日で判定
    const yearmonth = Number(year + month);
    return yearmonth;
  }

  //同一建物追加
  private addFacilityItem() {
    if (!this.office.office_history_same_buildings) {
      this.office.office_history_same_buildings = [];
    }
    this.office.office_history_same_buildings.push(
      DefaultOfficeHistorySameBuilding()
    );
  }

  //同一建物削除
  private deleteFacilityItem(item: unknown) {
    if (!this.office.office_history_same_buildings) {
      this.office.office_history_same_buildings = [];
    }
    this.office.office_history_same_buildings = this.office.office_history_same_buildings.filter(
      facility => facility != item
    );
  }

  //適用開始月ルール
  private checkStart(date: string): boolean | string {
    if (date.slice(0, 7) < "1970-01") {
      return "1970年以降を指定してください";
    }
    return this.required(date, "必須です");
  }

  //事業所名ルール
  private requiredOfficeName(name: string): boolean | string {
    return this.required(name, "必須です");
  }

  //医療機関コードルール
  private ruleMedicalInstCode(): boolean | string {
    if (this.office.medical_institution_code.length !== 7) {
      return "半角数字7文字でご記入ください";
    }
    return true;
  }

  //事業所コードルール
  private ruleOfficeCode(): boolean | string {
    if (this.office.office_code.length !== 10) {
      return "半角数字10文字でご記入ください";
    }
    if (this.office.pref !== 0) {
      const prefName = this.prefName(this.office.pref);
      const prefCode = ("00" + this.office.pref).slice(-2);
      if (prefCode !== this.office.office_code.slice(0, 2)) {
        return `住所に${prefName}を選択している場合、先頭2文字は${prefCode}にしてください`;
      }
    }
    return true;
  }

  //定期巡回サービス提供ルール
  private requiredCareRegularVisitServiceExist(): boolean | string {
    if (this.office.care_regular_visit_service_exist == 0) {
      return true;
    }

    if (this.office.care_kinkyu_houmon_add > 0) {
      return true;
    }

    return "定期巡回サービス提供には緊急時訪問看護加算の選択が必要です";
  }

  //委託者コードルール
  private requiredEdiCode(): boolean | string {
    if (this.office.use_edi_flg == 0) {
      return true;
    }

    const ediCode = this.required(this.office.edi_code, "必須です");
    if (ediCode == "必須です") {
      return ediCode;
    }

    if (this.office.edi_code.length > 10) {
      return "10文字以内で入力してください。";
    }

    return true;
  }

  //委託者名ルール
  private requiredEdiName(): boolean | string {
    if (this.office.use_edi_flg == 0) {
      return true;
    }

    const ediName = this.required(this.office.edi_name, "必須です");
    if (ediName == "必須です") {
      return ediName;
    }

    return this.hankanaBank(
      this.office.edi_name,
      "委託者名に使用できる文字で記入してください"
    );
  }

  //振替日ルール
  private requireTransferDate(): boolean | string {
    if (this.office.transfer_div == 0) {
      return true;
    }

    if (this.office.transfer_day > 0 && this.office.transfer_day < 32) {
      return true;
    }
    return "振替日は1～31の半角数字で入力してください";
  }

  //24時間対応体制加算ルール
  private requiredHour24TaiouAdd(): boolean | string {
    if (
      !this.office.medical_hour24_taiou_add &&
      this.office.medical_is_hour24_taiou_reduce_burden
    ) {
      return "24時間対応体制加算を「加算あり」にしてください。";
    }
    return true;
  }

  //機能強化型訪問看護管理療養費ルール
  private requireMedicalKinouKyokaRyouyouhi(
    ryoyouhi: number
  ): boolean | string {
    if (!ryoyouhi) {
      return true;
    }

    const count =
      this.office.medical_kinou_kyoka_ryouyouhi1 +
      this.office.medical_kinou_kyoka_ryouyouhi2 +
      this.office.medical_kinou_kyoka_ryouyouhi3;
    if (count > 1) {
      return "機能強化型訪問看護管理療養費は１・２・３いずれかを選択してください。";
    }
    return true;
  }

  //管理療養費ルール
  private requireMedicalKanriRyouyouhi(): boolean | string {
    if (this.office.medical_kanri_ryouyouhi_div == 0) {
      return "令和6年の改定による訪問看護管理療養費に係る届出に基づき設定してください。届出が完了していない場合は、令和６年９月 30 日までの間に限り、訪問看護管理療養費１の基準に該当するものとみなす経過措置があります。";
    }
    return true;
  }

  //特別地域加算 or 中山間地域等小規模事業所加算ルール
  private requireSpecialAreaSmallArea(): boolean | string {
    if (
      this.office.care_special_area_div &&
      this.office.care_small_office_scale_add
    ) {
      return "特別地域加算と中山間地域等小規模事業所加算は同時に算定できません。";
    }
    return true;
  }

  //適格請求書用登録番号
  private requireInvoiceNo(): boolean | string {
    if (
      this.office.invoice_no.length > 0 &&
      this.office.invoice_no.length != 14
    ) {
      return "半角英数14文字でご記入ください。";
    }
    return true;
  }

  //ベースアップ評価料ルール
  private requiredMedicalBaseUp1(): boolean | string {
    if (
      !this.office.medical_base_up1 &&
      this.office.medical_base_up2
    ) {
      return "ベースアップ評価料(Ⅱ)はベースアップ評価料(Ⅰ)が必須です。";
    }
    return true;
  }
}
